import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const supportApi = createApi({
    reducerPath: "support",
    tagTypes: ['Support'],
    baseQuery: createCustomFetchBaseQuery(),
    endpoints: (builder) => ({
        // GET ALL CONTACT MESSAGES
        getAllContactMessages: builder.query({
            query: ({ pageno, limit, search , status}) => `admin/contact?pageno=${pageno + 1}&limit=${limit}&search=${search}&status=${status}`,
            transformResponse: (res) => res.body,
            providesTags: ['Support'],
        }),

        sendReply: builder.mutation({
            query: (data) => (
                {
                    url: `admin/support`,
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: data,
                }
            ),
            invalidatesTags: ['Support'],
        }),



    }),
});

export const {
    useGetAllContactMessagesQuery,
    useSendReplyMutation
} = supportApi;
