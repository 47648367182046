import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";


export const FaqsApi = createApi({
    reducerPath: "faqs",
    baseQuery: createCustomFetchBaseQuery(),
    tagTypes: ['Faq' ,'About' ,'PrivacyAndPolicy' ,'TermsAndConditions' ,'Support'],
    endpoints: (builder) => ({
        getAllFaqs: builder.query({
            query: () => ({
                url: `admin/faq`,
                method: "GET",
            }),
            transformResponse: (res) => res,
            providesTags: ['Faq'],
        }),

        addNewFaq: builder.mutation({
            query: (newFaq) => (
                {
                    url: `admin/faq`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: newFaq,
                }),
            invalidatesTags: ['Faq'],
        }),

        updateFaq: builder.mutation({
            query: (updatedFaq) => ({
                url: `admin/faq`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: updatedFaq
            }),
            invalidatesTags: ['Faq'],
        }),

        deleteFaq: builder.mutation({
            query: (id) => ({
                url: `admin/faq`,
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: { faq_id: id }
            }),
           invalidatesTags:['Faq']
        }),

        getAbout: builder.query({
            query: () => ({
                url: `admin/about`,
                method: "GET",
            }),
            transformResponse: (res) => res.body.about,
            providesTags: ['About'],
        }),

        getPrivacyAndPolicy: builder.query({
            query: () => ({
                url: `admin/privacy`,
                method: "GET",
            }),
            transformResponse: (res) => res.body.privacy,
            providesTags: ['PrivacyAndPolicy'],
        }),

        getTermsAndConditions: builder.query({
            query: () => ({
                url: `admin/tac`,
                method: "GET",
            }),
            transformResponse: (res) => res.body.tac,
            providesTags: ['TermsAndConditions'],
        }),

        addSupport: builder.mutation({
            query: (newSupport) => ({
                url: `admin/support`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: newSupport,
            }),
            invalidatesTags: ['Support'],
        }),
    }),
});

export const { useGetAllFaqsQuery, useAddNewFaqMutation, useUpdateFaqMutation , useDeleteFaqMutation , useGetAboutQuery , useGetPrivacyAndPolicyQuery , useGetTermsAndConditionsQuery , useAddSupportMutation } = FaqsApi;
 