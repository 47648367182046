import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { useState } from 'react';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import { MenuItem } from '@mui/material';
import { usePopover } from 'src/components/custom-popover';
import { useDeleteSystemMutation } from 'src/store/Reducer/system';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  // onDeleteRow,
  index,
  counter
}) {
  const { title, _id } = row;


  const [edit, setEdit] = useState(null);
  const [DeleteSystem ,{isLoading}] = useDeleteSystemMutation()

  const confirm = useBoolean();
  const popover = usePopover()

  const quickEdit = useBoolean();


  const handleDelete =async () => {
    const { error } = await DeleteSystem(_id).unwrap()
    if (!error) {
      confirm.onFalse()
      popover.onClose()
      enqueueSnackbar('Deleted Successfully')
    }
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{counter}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', marginTop: '10px' }}>{title}</TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon="solar:pen-bold" onClick={() => setEdit(row)} />
            </IconButton>

         

          </Tooltip>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={confirm.onTrue}>
              <Iconify icon="mdi:delete" />
            </IconButton>
        </TableCell>
      </TableRow>

      <UserQuickEditForm currentUser={edit} open={edit} onClose={() => setEdit(null)} />


      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>


      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <LoadingButton variant="contained" color="error" onClick={handleDelete} loading={isLoading} disabled={isLoading}>
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
